import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import "./index.css"

import { Link } from 'gatsby';

import HomePageYAMLData from "../../content/home.yaml"

export default () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let {AMap} = window;
    let lnglat = new AMap.LngLat(116.326255,39.880997);
    // let marker,map = new AMap.Map('allmap', {
    //   resizeEnable: true,
    //   zoom: 18,
    //   center: [116.326255,39.880997],
    //   lang: "zh_cn" //可选值：en，zh_en, zh_cn
    //
    // });
    // marker = new AMap.Marker({
    //   icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png",
    //   position: [116.326255,39.880997],
    //   offset: new AMap.Pixel(-13, -30)
    // });
    // marker.setMap(map);
    let map = new AMap.Map("allmap", {
      resizeEnable: true,
      center: lnglat,
      zoom: 18,
      lang: "zh_cn"
    });
    addMarker();

//添加marker标记
    function addMarker() {
      map.clearMap();
      let marker = new AMap.Marker({
        map: map,
        position: [116.326255,39.880997]
      });
      //鼠标点击marker弹出自定义的信息窗体
      AMap.event.addListener(marker, 'click', function () {
        infoWindow.open(map);
      });
    }
//实例化信息窗体
    let title = '埃斯科影像核心实验室',
      content = [];
    content.push("地址：北京市西城区马连道路19号十四层");
    content.push("电话：010-63328123");
    content.push("邮箱：info@imagecorelabcn.com");
    let infoWindow = new AMap.InfoWindow({
      isCustom: true,  //使用自定义窗体
      position: lnglat,
      content: createInfoWindow(title, content.join("<br/>")),
      offset: new AMap.Pixel(16, -45)
    });

      infoWindow.open(map);

//构建自定义信息窗体
    function createInfoWindow(title, content) {
      let info = document.createElement("div");
      info.className = "custom-info input-card content-window-card";

      //可以通过下面的方式修改自定义窗体的宽高
      //info.style.width = "400px";
      // 定义顶部标题
      let top = document.createElement("div");
      let titleD = document.createElement("div");
      let closeX = document.createElement("img");
      top.className = "info-top";
      titleD.innerHTML = title;
      closeX.src = "https://webapi.amap.com/images/close2.gif";
      closeX.onclick = closeInfoWindow;

      top.appendChild(titleD);
      top.appendChild(closeX);
      info.appendChild(top);

      // 定义中部内容
      let middle = document.createElement("div");
      middle.className = "info-middle";
      middle.style.backgroundColor = 'white';
      middle.innerHTML = content;
      info.appendChild(middle);

      // 定义底部内容
      let bottom = document.createElement("div");
      bottom.className = "info-bottom";
      bottom.style.position = 'relative';
      bottom.style.top = '0px';
      bottom.style.margin = '0 auto';
      let sharp = document.createElement("img");
      sharp.src = "https://webapi.amap.com/images/sharp.png";
      bottom.appendChild(sharp);
      info.appendChild(bottom);
      return info;
    }

//关闭信息窗体
    function closeInfoWindow() {
      map.clearInfoWindow();
    }

  }, [])
  return (
    <Layout pageId={"/"} onClick={() => {setShow(false); }}>
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" >
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className='banner_1'>
              <div className="container h-100">
                <div className='row h-100 align-items-center'>
                  <div className='row banner_title'>
                    <div className='col-lg-auto col-12  px-0'>
                      {HomePageYAMLData.index_title}
                      <img src='/images/header/Logo-eye.png' style={{ width: 74, marginTop: -120 }}/>
                    </div>
                    <div className='w-100'/>
                    <div className='col-auto px-0 banner_msg'>
                      {HomePageYAMLData.index_subTitle_1}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<img src="/images/index/Mask.png" className="d-block w-100" alt="..."/>*/}
          </div>
          <div className="carousel-item">
            <div className='banner_4'>
              <div className="container h-100">
                <div className='row h-100 align-items-center'>
                  <div className='row banner_title'>
                    <div className='col-auto px-0'>
                      {HomePageYAMLData.index_title}
                      <img src='/images/header/Logo-eye.png' style={{ width: 74, marginTop: -120 }}/>
                    </div>
                    <div className='w-100'/>
                    <div className='col-auto px-0 banner_msg' >
                      {HomePageYAMLData.index_subTitle_2}
                      {/*智能*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<img src="/images/index/Mask_2.png" className="d-block w-100" alt="..."/>*/}
          </div>
          <div className="carousel-item">
            <div className='banner_2'>
              <div className="container h-100">
                <div className='row h-100 align-items-center'>
                  <div className='row banner_title'>
                    <div className='col-auto px-0'>
                      {HomePageYAMLData.index_title}
                      <img src='/images/header/Logo-eye.png' style={{ width: 74, marginTop: -120 }}/>
                    </div>
                    <div className='w-100'/>
                    <div className='col-auto px-0 banner_msg' >
                      {HomePageYAMLData.index_subTitle_3}
                      {/*精准*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<img src="/images/index/Mask_2.png" className="d-block w-100" alt="..."/>*/}
          </div>
          <div className="carousel-item">
            <div className='banner_3'>
              <div className="container h-100">
                <div className='row h-100 align-items-center'>
                  <div className='row banner_title'>
                    <div className='col-auto px-0'>
                      {HomePageYAMLData.index_title}
                      <img src='/images/header/Logo-eye.png' style={{ width: 74, marginTop: -120 }}/>
                    </div>
                    <div className='w-100'/>
                    <div className='col-auto px-0 banner_msg' >
                      {HomePageYAMLData.index_subTitle_4}
                      {/*高效*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<img src="/images/index/Mask_2.png" className="d-block w-100" alt="..."/>*/}
          </div>

        </div>
        {/*<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">*/}
        {/*  <span className="carousel-control-prev-icon" aria-hidden="true"></span>*/}
        {/*  <span className="sr-only">Previous</span>*/}
        {/*</a>*/}
        {/*<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">*/}
        {/*  <span className="carousel-control-next-icon" aria-hidden="true"></span>*/}
        {/*  <span className="sr-only">Next</span>*/}
        {/*</a>*/}
      </div>
      <div className="container ">
        <div className={"row align-items-center justify-content-center"} style={{ height: 479 }}>
          <div className='col-lg-8 col-12 px-0'>
            <div style={{ color: "#565656", fontSize: 18, lineHeight: "30px" }}>
              {HomePageYAMLData.index_intro}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundColor: "#EBEBEB" }}>
        <div className="container">
          <div className="row align-items-center justify-content-center" style={{ height: 170 }}>
            <div style={{ fontSize: 30, color: "#41474D" }}>
              {HomePageYAMLData.index_category_title_1}
              {/*项目展示*/}
            </div>
          </div>
        </div>
        <div className="container">
          <div className='row justify-content-center'>
            <div className="col-lg-3 col-12">
              {/*<a href="/partner#cassiss">*/}
              <Link to={`/partner#cassiss`}>
              <img src="/images/index/CASSISS.jpg" className={"img-fluid img_shadow w-100"}
                   onClick={() => {
                     // window.location.href = '/partner#cassiss';

                   }}
              />
              </Link>
              {/*</a>*/}
              <div className='row justify-content-center align-items-center' style={{ marginTop: 39 }}>
                <div className="row justify-content-center align-items-center">
                  <div className='col-auto'>
                    <div style={{ color: "#454545", fontSize: 30, padding: "0 15px" }}>
                      {HomePageYAMLData.index_category_title_1_1}
                      {/*CASSISS*/}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className='col-auto'>
                    <div style={{
                      color: "#454545",
                      fontSize: 14,
                      padding: "0 15px",
                      lineHeight: "25px",
                      margin: "20px 0px 40px 0px",
                    }}>
                      {HomePageYAMLData.index_category_title_1_content_1}
                      {/*中国神经介入领域第一个关于颅内动脉粥样硬化狭窄介入治疗与药物治疗对比的多中心随机对照试验。*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <Link to={`/partner#cmoss`}>
                <img src="/images/index/CMOSS.jpg" className='img-fluid img_shadow w-100'
                     // onClick={() => {window.location.href = '/partner#cmoss';}}
                />
              </Link>
              <div className='row justify-content-center align-items-center' style={{ marginTop: 39 }}>
                <div className="row justify-content-center align-items-center">
                  <div className='col-auto'>
                    <div style={{ color: "#454545", fontSize: 30, padding: "0 15px" }}>
                      {HomePageYAMLData.index_category_title_1_2}
                      {/*CMOSS*/}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className='col-auto'>
                    <div style={{
                      color: "#454545",
                      fontSize: 14,
                      padding: "0 15px",
                      lineHeight: "25px",
                      margin: "20px 0px 40px 0px",
                    }}>
                      {HomePageYAMLData.index_category_title_1_content_2}
                      {/*中国第一个关于颈动脉或大脑中动脉闭塞搭桥手术治疗与药物治疗对比的多中心随机对照试验。*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <Link to={`/partner#cmoss`}>
                <img src="/images/index/other.jpg" className='img-fluid img_shadow w-100'
                    // onClick={() => {window.location.href = '/partner#cmoss';}}
                />
              </Link>
              <div className='row justify-content-center align-items-center' style={{ marginTop: 39 }}>
                <div className="row justify-content-center align-items-center">
                  <div className='col-auto'>
                    <div style={{ color: "#454545", fontSize: 30, height: 45,}}>
                      {HomePageYAMLData.index_category_title_1_3}
                      {/*CMOSS*/}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className='col-auto'>
                    <div style={{
                      color: "#454545",
                      fontSize: 14,
                      padding: "0 15px",
                      lineHeight: "25px",
                      margin: "20px 0px 40px 0px",
                    }}>
                      {HomePageYAMLData.index_category_title_1_content_3}
                      {/*中国第一个关于颈动脉或大脑中动脉闭塞搭桥手术治疗与药物治疗对比的多中心随机对照试验。*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-end">
            <div style={{
              width: 97,
              height: 36,
              textAlign: "center",
              border: "1px solid rgba(112,112,112,1)",
              lineHeight: "36px",
              color: "#707070",
              marginBottom: 80,
            }} onClick={() => {window.location.href = '/partner';}}>
              {HomePageYAMLData.index_category_title_1_button_1}
              {/*了解更多*/}
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='container'>
          <div className="row align-items-center justify-content-center" style={{ height: 150 }}>
            <div style={{ fontSize: 30, color: "#41474D" }}>
              {HomePageYAMLData.index_category_title_2}
              {/*业务*/}
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className='col-lg-4 col-6'>
              <Link to={`/business#DSA`}>
              <div className='img_shadow business_div'
                   // onClick={() => {window.location.href = '/business'}}
              >
                <img src='/images/index/DSA.png' className="w-100"/>
                <div className='business_item'>
                  {HomePageYAMLData.index_category_title_2_1}
                  {/*脑血管造影定性和定量分析（DSA）*/}
                </div>
              </div>
              </Link>
            </div>
            <div className='col-lg-4 col-6'>
              <Link to={`/business#MRI`}>
              <div className='img_shadow business_div'
                   // onClick={() => {window.location.href = '/business'}}
              >
                <img src='/images/index/MRI.png' className="w-100"/>
                <div className='business_item'>
                  {HomePageYAMLData.index_category_title_2_2}
                  {/*脑核磁共振定性和定量分析（MRI）*/}
                </div>
              </div>
              </Link>
            </div>
            <div className='col-lg-4 col-6'>
              <Link to={`/business#HRMRI`}>
              <div className='img_shadow business_div'
                   // onClick={() => {window.location.href = '/business'}}
              >
                <img src='/images/index/HRRMRI.png' className="w-100"/>
                <div className='business_item'>
                  {HomePageYAMLData.index_category_title_2_3}
                  {/*脑血管高分辨核磁共振分析（HRMRI）*/}
                </div>
              </div>
              </Link>
            </div>
            <div className='col-lg-4 col-6'>
              <Link to={`/business#OCT`}>
              <div className='img_shadow business_div'
                   // onClick={() => {window.location.href = '/business'}}
              >
                <img src='/images/index/OCT.png' className="w-100"/>
                <div className='business_item'>
                  {HomePageYAMLData.index_category_title_2_4}
                  {/*血管内光学相干断层成像（OCT）*/}
                </div>
              </div>
              </Link>
            </div>
            <div className='col-lg-4 col-6'>
              <Link to={`/business#CFD`}>
              <div className='img_shadow business_div'
                   // onClick={() => {window.location.href = '/business'}}
              >
                <img src='/images/index/CFD.png' className="w-100"/>
                <div className='business_item'>
                  {HomePageYAMLData.index_category_title_2_5}
                  {/*计算机流体力学分析（CFD）*/}
                </div>
              </div>
              </Link>

            </div>
            <div className='col-lg-4 col-6'>
              <Link to={`/business#VU`}>
              <div className='img_shadow business_div'
                   // onClick={() => {window.location.href = '/business'}}
              >
                <img src='/images/index/TCD.png' className="w-100"/>
                <div className='business_item'>
                  {HomePageYAMLData.index_category_title_2_6}
                  {/*血管超声分析（VU）*/}
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='container'>
          <div className="row align-items-center justify-content-center" style={{ height: 150 }}>
            <div style={{ fontSize: 30, color: "#41474D" }}>
              {HomePageYAMLData.index_category_title_3}
              {/*联系我们*/}
            </div>
          </div>
          <div className='row justify-content-center align-items-start' style={{ height: 150 }}>
            <div className='col-4'>
              <div className='info'>
              {HomePageYAMLData.index_category_title_3_1}
                {/*info@imagecorelabcn.com*/}
              </div>
            </div>
            <div className='col-4 border-right border-left '
                 style={{ borderColor: "#707070" }}>
              <div className='info'>
              {HomePageYAMLData.index_category_title_3_2}
                {/*010-63328123*/}
              </div>
            </div>
            <div className='col-4' >
              <div className='info'>
              {HomePageYAMLData.index_category_title_3_3}
                {/*北京市西城区马连道路19号十四层*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div id='allmap' className='w-100' style={{height: 400}}/>
        <div className='w-100' style={{ height: 240 }}/>
      </div>

      <div style={{
        width: 40,
        height: 40,
        backgroundColor: "#056EBE",
        position: "fixed",
        right: 0,
        zIndex: 1000,
        bottom: '50%',
        cursor: 'pointer'
      }} >
        <div style={{padding: 4, }}>
          <img src='/images/index/phone.png' className={"w-100"} onClick={(event) => {
            event.stopPropagation();
            setShow(!show)
          }}/>
        </div>

        {
          show && <div className='form-model' onClick={(event) => { event.stopPropagation();}}>
            <div className='container '>
              <div className='row mx-0 justify-content-center align-items-start'>
                <div className='col-auto px-0'>
                  <div style={{
                    height: 80,
                    lineHeight: "80px",
                    letterSpacing: 3,
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: 23,
                  }}>
                    {HomePageYAMLData.index_phone_form_title}
                    {/*联系我们*/}
                  </div>
                </div>
              </div>
              <div className='row mx-0 justify-content-center align-items-start'>
                <div className='col-5 px-0'>
                  <div style={{ paddingRight: 5 }}>
                    <input type="text" className='w-100 input' placeholder={HomePageYAMLData.index_phone_form_input_1}/>
                  </div>
                </div>
                <div className='col-5 px-0'>
                  <div style={{ paddingLeft: 5 }}>
                    <input type="tel" className='w-100 input' placeholder={HomePageYAMLData.index_phone_form_input_2}/>
                  </div>
                </div>
              </div>
              <div className='row mx-0 justify-content-center align-items-start'>
                <div className='col-10 px-0' style={{ marginTop: 20 }}>
                  <input type="text" className='w-100 input' placeholder={HomePageYAMLData.index_phone_form_input_3}/>
                </div>
              </div>
              <div className='row mx-0 justify-content-center align-items-start'>
                <div className='col-10 px-0' style={{ marginTop: 20 }}>
                  <textarea cols="4" className='w-100 textarea' placeholder={HomePageYAMLData.index_phone_form_input_4}/>
                </div>
              </div>

              <div className='row mx-0 justify-content-center align-items-start'>
                <div className='col-10 px-0' style={{ marginTop: 20 }}>
                  <div className='button'>
                    {HomePageYAMLData.index_phone_form_button}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </Layout>
  )
}

